import responseAPI from "@/api/templates-api";

const state = {
  templates: []

};

const mutations = {
  ADD_ALL(state, templates) {
    state.templates = Object.values(templates);
  },
  ADD_TEMPLATE(state, response) {
    state.templates = [...state.templates, response];
  },
  UPDATE_TEMPLATE(state, responseTemplate) {
    console.log("Update Template Mutation: ", responseTemplate);
    const updateIndex = state.templates.findIndex(
      (item) => item.id === responseTemplate.id
    );
    console.log("Found Index: ", updateIndex);

    state.templates = [
      ...state.templates.slice(0, updateIndex),
      responseTemplate,
      ...state.templates.slice(updateIndex + 1),
    ];
  },
  REMOVE_TEMPLATE(state, id) {
    let templates = state.templates.filter((t) => t.id != id);
    state.templates = templates;
  },
};

const actions = {
  async updateTemplate({ commit }, responseTemplate) {
    let response = "";
    if (responseTemplate.id === undefined) {
      response = await responseAPI.add(responseTemplate);
      console.log("Sent Template to server, got response ", response);
      return commit("ADD_TEMPLATE", response.data);
    } else {
      response = await responseAPI.update(responseTemplate);
      console.log("Sent Template to server, got response ", response);
      return commit("UPDATE_TEMPLATE", response.data);
    }
  },
  async loadAllTemplates({ commit, state }) {
    if(state.templates.length===0) {
      const response = await responseAPI.list();
      let templates = await response.data;
      commit("ADD_ALL", templates);
    }
  },

  async updateTimesUsed({ commit }, id) {
    const response = await responseAPI.updateTemplateTimesUsed(id);
    console.log("Received delete response :", response);
    if (response.status === 200) {
      commit("UPDATE_TEMPLATE", response.data);
    }
  },

  async deleteTemplate({ commit }, id) {
    const response = await responseAPI.remove(id);
    console.log("Received delete response :", response);
    if (response.status === 200) {
      commit("REMOVE_TEMPLATE", id);
    }
  },
};


const namespaced = true;

export default {
  state,
  mutations,
  actions,
  namespaced,
};
