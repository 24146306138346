import axios from "axios";
// import Vue from 'vue'


const ax = axios.create({
  baseURL: ``,
  timeout: 60000
});

const conversationPath = "/conversation";
const conversationSearchPath = "/conversation/search"
const conversationApolloPath = "/conversation/apollo"
const conversationIdsPath = "/conversation/ids"
const snapshotIdPath = "/conversation/snapshot"




export default {
  async setToken(token) {
    ax.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  async add(conversation){
    return  ax.post(conversationPath, conversation);
  },

  async findApolloLeads(domainName){
    const data = await ax.get(`${conversationApolloPath}/${domainName}`);
    return data;
  },

  async updateConversation(payload){
    return ax.put(conversationPath, payload);
  },
  async delete(payload){
    const {id, block} = payload;
    console.log("Attempting to block", payload)
     ax.delete(`${conversationPath}/${id}`, {params: {block}}, {timeout: 60000});
  },
  async search(payload){
    const response = await ax.get(conversationSearchPath, {params: payload},  {timeout: 60000});
    return response;
  },
  async loadById(id){
    const response = await ax.get(`${conversationPath}/${id}`,  {timeout: 60000});
    return response.data;
  },
  async postByLead(payload){
    const response = await ax.post(`${conversationPath}/lead/`,payload ,  {timeout: 60000});
    return response.data;
  },
  async postByPhone(payload){
    const response = await ax.post(`${conversationPath}/lead/`,payload ,  {timeout: 60000});
    return response.data;
  },
  async postByEmail(payload){
    const response = await ax.post(`${conversationPath}/lead/`,payload ,  {timeout: 60000});
    return response.data;
  },
  async postByApollo(payload){
    const response = await ax.post(`${conversationPath}/apollo/`,payload ,  {timeout: 60000});
    return response.data;
  },
  async loadByPhoneNumber(phone){
    const response = await ax.get(`${conversationPath}/phone/${phone}`,  {timeout: 60000});
    return response.data;
  },
  async loadByDomainId(id){
    const response = await ax.get(`${conversationPath}/domain/${id}`,  {timeout: 60000});
    return response.data;
  },
  async loadByDomainName(name){
    const response = await ax.get(`${conversationPath}/domainName/${name}`,  {timeout: 60000});
    return response.data;
  },
  async loadSnapshotById(id){
    const response = await ax.get(`${snapshotIdPath}/${id}`,  {timeout: 60000});
    return response.data;
  },
  async loadIds(){
    const response = await ax.get(conversationIdsPath,  {timeout: 60000});
    return response.data;
  },

  async loadByMessageId(id) {
    const response = await ax.get(`${conversationPath}/message/${id}`,  {timeout: 60000});
    return response.data;
  }
};
