import axios from "axios";
// import Vue from 'vue'


const api = axios.create({
  baseURL: ``,
  timeout: 60000
});

const path = "/templates";

export default {
  async add(response){
    const data = await api.post(path, response);
    return data;
  },
  async update(response){
    return api.put(`${path}/${response.id}`, response);
  },
  async updateTemplateTimesUsed(id){
    return api.put(`${path}/increment/${id}`);
  },
  async remove(id){
    return api.delete(`${path}/${id}`);
  },
  async list(){
    const data = api.get(path, {timeout: 60000});
    return data;
  },
  async search(searchTerm){
    const data = api.get(path, {params: { searchTerm }},  {timeout: 60000});
    return data;
  },
  async setToken(token) {
    console.log("setting auth header to", token);
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }


};
