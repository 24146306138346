// store/modules/search.js

export default {
    namespaced: true,
    state: {
        menu: false,
        calendar: false,
        awaitingSearch: false,
        searchTerm: '',
        dates: [],
        conversationStage: '',
    },
    mutations: {
        setMenu(state, value) {
            state.menu = value;
        },
        setCalendar(state, value) {
            state.calendar = value;
        },
        setSearchTerm(state, value) {
            state.searchTerm = value;
        },
        setDates(state, value) {
            state.dates = value;
        },
        setConversationStage(state, value) {
            state.conversationStage = value;
        },
        updateDate(state, { value, view }) {
            const date = {
                date: new Date(value),
                view: view
            };
            const updateIndex = state.dates.findIndex(item => item.view === date.view);
            if (updateIndex === -1) {
                state.dates.push(date);
            } else {
                state.dates = [
                    ...state.dates.slice(0, updateIndex),
                    date,
                    ...state.dates.slice(updateIndex + 1)
                ];
            }
        },
        clearFilter(state) {
            state.searchTerm = '';
            state.conversationStage = '';
        }
    },
    actions: {
        search({ state, dispatch }, view) {
            const searchFilter = {
                date: state.dates.find(d => d.view === view)?.date || new Date(),
                searchTerm: state.searchTerm,
                conversationStage: state.conversationStage
            };
            return dispatch(`${view}/search`, searchFilter, { root: true });
        },
        dateAddDays({ state, commit, dispatch }, { days, view }) {
            const datePerView = state.dates.find(d => d.view === view) || { date: new Date(), view };
            const newDropDate = new Date(datePerView.date);
            newDropDate.setDate(newDropDate.getDate() + days);
            commit('updateDate', { value: newDropDate, view });
            dispatch('search', view);
        },
        setDate({ commit, dispatch }, { date, view }) {
            commit('updateDate', { value: date, view });
            dispatch('search', view);
        }
    },
    getters: {
        getDate: (state) => (view) => {
            let date = state.dates.find(d => d.view === view);
            if (!date) {
                date = {
                    date: new Date(),
                    view: view
                };
                state.dates = state.dates.concat(date);
            }
            return date;
        }
    }
};
